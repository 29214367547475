import { graphql, navigate } from 'gatsby';
import React, { Component } from 'react';
import { Footer, Layout, References, Scheduler } from '../components';
import { ObjectUtils, ResourceUtils } from '../utils';
import LocalizationService from '../services/localization-service';

const PAGE_MICROCOPY_MAP = {
  heroBody: 'hero.body',
  heroTitle: 'hero.title',
  noIndex: 'page.no_index',
  pageSlug: 'page.slug',
  pageTitle: 'page.title',
  promoBody: 'promo.body',
  promoTitle: 'promo.title',
};

export default class DownloadPage extends Component {

  state = {
    error: null
  };

  onScheduled = () => {
    navigate(`/thank-you/for-scheduling`);
  };

  render = () => {

    const { landingPageResource } = this.props.data;
    const { name: landingPageResourceName, resources: landingPageResources } = landingPageResource;
    const [landingPageKeyPrefix, landingPageKey] = landingPageResourceName.split('.');
    const fullKeyPrefix = `${landingPageKeyPrefix}.${landingPageKey}`;
    console.log('landingPageResources', ResourceUtils.convertResourceSetToMap(landingPageResources, fullKeyPrefix));
    const microcopy = ResourceUtils.getMicrocopy(new LocalizationService(landingPageResources), fullKeyPrefix, PAGE_MICROCOPY_MAP);
    const resourceMap = ResourceUtils.convertResourceSetToMap(landingPageResources, fullKeyPrefix);
    const resourceObject = ObjectUtils.fromMap(resourceMap);
    console.log('resourceMap', resourceMap);
    console.log('resoureObject', resourceObject);

    return <Layout className="offer-page">
      <section className="bg-brand-neutral-3 py-4 text-white banner-component bg-primary position-relative">
        <div className="container">
          <div className="row g-5">
            <div className="col-12 text-center">
              <h2 className="mb-0 pb-0">{microcopy.pageTitle}</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-brand-neutral-2 py-4 text-white">
        <div className="container">
          <div className="row g-5">
            <div className="col-12 text-center text-md-start text-brand-neutral-3">
              <h3>{microcopy.heroTitle}</h3>
              <div className="mb-0">{microcopy.heroBody}</div>
            </div>
          </div>
        </div>
      </section>
      <div className="container mt-4">
        <div className="row">
          <div className="col-12 col-md-6 mt-4">
            <h4 className="">{microcopy.promoTitle}</h4>
            <div className="">
              {microcopy.promoBody}
            </div>
          </div>
          <Scheduler className="col-12 col-md-6" onScheduled={this.onScheduled} pageKey={landingPageKey} />
        </div>
      </div>
      <References pageData={this.props.data} references={resourceObject.references} />
      <Footer />
    </Layout>;
  };
};

export const query = graphql`
  query OfferPageQuery($landingPageResourceName: String!, $downloadContentResourceName: String) {
    landingPageResource: contentfulResourceSet(name: {eq: $landingPageResourceName}) {
      id
      name
      resources {
        key
        value {
          value
        }
        isHtml
      }
    }
    downloadContentResource: contentfulResourceSet(name: {eq: $downloadContentResourceName}) {
        id
        name
        resources {
          key
          value {
            value
          }
          isHtml
        }
    }
    downloadContent: allContentfulResourceSet(filter: {name: {regex: "/^downloads\\.content.*/"}}) {
        edges {
            node {
                name
                resources {
                    key
                    value {
                        value
                    }
                    isHtml
                }
            }
        }
    }
  }
`;